import dayjs from 'dayjs';
import { IOrganization } from 'app/shared/model/organization.model';

export interface IClassroom {
  id?: number;
  code?: string | null;
  name?: string;
  expiryDate?: string | null;
  status?: string | null;
  organization?: IOrganization | null;
}

export const defaultValue: Readonly<IClassroom> = {};
