import { StatusEnum } from 'app/shared/model/enumerations/status-enum.model';

export interface ICompany {
  id?: number;
  code?: string;
  name?: string;
  profilePictureId?: string | null;
  notes?: string | null;
  status?: StatusEnum;
  phone?: string | null;
}

export const defaultValue: Readonly<ICompany> = {};
