import { ICompany } from 'app/shared/model/company.model';
import { StatusEnum } from 'app/shared/model/enumerations/status-enum.model';

export interface IOrganization {
  id?: number;
  code?: string;
  name?: string;
  profilePictureId?: string | null;
  notes?: string | null;
  phone?: string | null;
  status?: StatusEnum;
  company?: ICompany;
}

export const defaultValue: Readonly<IOrganization> = {};
