import { BookStatusEnum } from 'app/shared/model/enumerations/book-status-enum.model';

export interface IBook {
  id?: number;
  productId?: number;
  bookId?: string;
  name?: string | null;
  isbn?: string | null;
  description?: string | null;
  barcode?: string | null;
  articleCode?: string | null;
  authorCode?: string | null;
  authorName?: string | null;
  publisherCode?: string | null;
  publisherName?: string | null;
  categoryCode?: string | null;
  categoryName?: string | null;
  categoryL1Code?: string | null;
  categoryL1Name?: string | null;
  categoryL2Code?: string | null;
  categoryL2Name?: string | null;
  category?: string | null;
  vendorCode?: string | null;
  thumbnailUrl?: string | null;
  noOfPages?: number | null;
  priceFull?: number | null;
  priceDiscount?: number | null;
  priceDollar?: number | null;
  hasPdfFile?: boolean | null;
  hasEpubFile?: boolean | null;
  hasSamplePdfFile?: boolean | null;
  hasSampleEpubFile?: boolean | null;
  key?: string;
  status?: BookStatusEnum;
}

export const defaultValue: Readonly<IBook> = {
  hasPdfFile: false,
  hasEpubFile: false,
  hasSamplePdfFile: false,
  hasSampleEpubFile: false,
};
